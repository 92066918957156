import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "react-bootstrap"


const News = ({ data }) => (
  <Layout>
    <SEO title="News &amp; Events" />
    <Container fluid >
      <Row>
        <Col>
        <h1 style={{
        textAlign: 'center',
        marginTop: '2rem',
        fontWeight: 'bold',
        fontSize: '4rem'
      }}>News &amp; Events</h1>
      
        </Col>
      </Row>
      
    </Container>
  </Layout>
)

export const query = graphql`
  query {
    wavesm: file(relativePath:{eq:"wave@2x.png"}) {
      childImageSharp {
        fluid(quality:90, maxWidth:1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default News
